import React from 'react';
import PropTypes from 'prop-types';
import { toDashed } from 'utils/string';
import { Link } from 'react-router-dom';

const SearchLink = ({ className, itemProp, town, speciality, language, children }) => {
  const languageUrl = language && language.toLowerCase() !== 'english' ? `/${language.toLowerCase()}` : '';
  return (
    <Link
      className={className}
      itemProp={itemProp}
      to={`/specialists/${toDashed(town)}/${toDashed(speciality)}${languageUrl}`}
    >
      {children}
    </Link>
  );
};

SearchLink.defaultProps = {
  language: '',
  className: '',
  itemProp: '',
};

SearchLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  town: PropTypes.string.isRequired,
  speciality: PropTypes.string.isRequired,
  language: PropTypes.string,
  className: PropTypes.string,
  itemProp: PropTypes.string,
};

export default SearchLink;
