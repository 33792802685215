import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import styles from './Map.scss';

class SpecialistMap extends React.Component {
  state = {
    isOpen: 0,
  };

  onToggleOpen = id => {
    this.setState({
      isOpen: id,
    });
  };

  render() {
    const { selected, practices } = this.props;
    return (
      <GoogleMap defaultZoom={12} center={{ lat: practices[selected].latitude, lng: practices[selected].longitude }}>
        {practices.map(practice => (
          <Marker
            key={practice.id}
            icon="/images/new/locator.png"
            position={{ lat: practice.latitude, lng: practice.longitude }}
            onClick={() => this.onToggleOpen(practice.id)}
          >
            {this.state.isOpen === practice.id && (
              <InfoWindow onCloseClick={() => this.onToggleOpen(0)}>
                <div className={styles.container}>
                  <div className={styles.info}>{practice.name}</div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  }
}

SpecialistMap.defaultProps = {
  selected: 0,
};

SpecialistMap.propTypes = {
  selected: PropTypes.number,
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      lng: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default withGoogleMap(SpecialistMap);
