import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';

import trackEvent from 'utils/trackEvent';

/**
 * Formats normalized phone number according to given formatString
 * @param phoneNumber String. e.g. 13338008404.
 * @param formatString String. e.g. 'NNN NNNN NNNN'.
 * @returns String.
 */
function format(phoneNumber, formatString) {
  let out = formatString;
  for (let i = 0, l = phoneNumber.length; i < l; i += 1) {
    out = out.replace('N', phoneNumber[i]);
  }
  return out;
}

class PhoneNumber extends React.Component {
  get phoneNumber() {
    return format(this.props.phoneNumber, config.format.phoneNumber);
  }

  handlePhoneNumberClick = () => {
    window.location.href = `tel:${this.props.phoneNumber}`;

    const { profileType, profileTypeId, profileTypeName } = this.props;
    trackEvent('Paid Traction', 'Call Number', `${profileType} (${profileTypeId}) ${profileTypeName}`);
  };

  render() {
    const { phoneNumber } = this.props;

    if (!phoneNumber) {
      return null;
    }

    return (
      <a
        href={`tel:${this.phoneNumber}`}
        className={`has-text-grey-dark ${this.props.className}`}
        onClick={this.handlePhoneNumberClick}
      >
        <span>{this.phoneNumber}</span>
        <meta itemProp="telephone" content={phoneNumber} />
      </a>
    );
  }
}

PhoneNumber.propTypes = {
  profileType: PropTypes.string.isRequired,
  profileTypeId: PropTypes.number.isRequired,
  profileTypeName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PhoneNumber;
