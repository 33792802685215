// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { Trans } from '@lingui/macro';

import type { ShowEnquireModal } from 'modules/modal/actions';
import { modalActions } from 'modules/modal/actions';

type Props = {
  dispatch: Dispatch<ShowEnquireModal>,
  profileType: string,
  profileTypeId: number,
  profileTypeName: string,
  text: string,
  componentClassName: string,
};

const EnquireButton = (props: Props) => {
  const { profileType, profileTypeId, profileTypeName, text, componentClassName, dispatch } = props;

  return (
    <span
      role="button"
      tabIndex={0}
      onClick={() => dispatch(modalActions.showEnquireModal(profileType, profileTypeId, profileTypeName))}
      className={componentClassName}
    >
      {text}
    </span>
  );
};

EnquireButton.defaultProps = {
  text: <Trans>Request a Callback</Trans>,
};

EnquireButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profileType: PropTypes.string.isRequired,
  profileTypeId: PropTypes.number.isRequired,
  profileTypeName: PropTypes.string.isRequired,
  text: PropTypes.element,
  componentClassName: PropTypes.string.isRequired,
};

export default connect()(EnquireButton);
