// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { t, Trans } from '@lingui/macro';

import { i18n } from 'utils/i18n';
import type { SpecialistViewFlowType } from 'modules/specialist/reducer';
import PhoneNumber from 'components/phoneNumber';
import EnquireButton from 'components/enquireButton';
import SpecialistMap from 'components/practice/map';
import ProfileLink from 'components/link/ProfileLink';
import SearchLink from 'components/link/SearchLink';
import type { ShowSpecialistFreeModal } from 'modules/modal/actions';
import { modalActions } from 'modules/modal/actions';

type Props = {
  specialist: SpecialistViewFlowType,
  dispatch: Dispatch<ShowSpecialistFreeModal>,
};

const SpecialistFree = (props: Props) => {
  const { specialist, dispatch } = props;
  const name = `${specialist.firstName} ${specialist.lastName}`;

  return (
    <div className="has-background-white-ter">
      <div itemScope itemType="http://schema.org/MedicalClinic" className="container pt-30 pb-30">
        {specialist.practices.map((practice, index) => (
          <div key={practice.id} className="has-background-white-bis pt-30 pb-30 pl-40 pr-40 mb-30">
            <div className="pb-10">
              <div className="columns">
                <div className="column is-8">
                  <div className="lt">
                    {!index && (
                      <div>
                        <h1 itemProp="name" className="has-text-primary title is-3 mb-0 pb-0">
                          {name}
                        </h1>

                        <SearchLink
                          itemProp="medicalSpecialty"
                          town={practice.town}
                          speciality={specialist.specialization}
                          className="has-text-dark pt-15 pb-15 fontweight-700"
                        >
                          {specialist.specialization}
                        </SearchLink>
                      </div>
                    )}
                    <div className="is-size-6 mb-5 has-text-weight-semibold has-text-grey-dark">
                      {!index &&
                        (specialist.phoneNumber || practice.phoneNumber) && (
                          <React.Fragment>
                            <Trans>Phone number:</Trans>{' '}
                            <PhoneNumber
                              phoneNumber={
                                !index && specialist.phoneNumber ? specialist.phoneNumber : practice.phoneNumber
                              }
                              profileType="specialist"
                              profileTypeId={specialist.id}
                              profileTypeName={name}
                            />
                          </React.Fragment>
                        )}
                    </div>

                    <div className="pt-80">
                      <i>
                        <Trans>In case of emergency call 999</Trans>
                      </i>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="rt">
                    <SpecialistMap practices={[practice]} />
                    <ProfileLink
                      profileType="practice"
                      town={practice.town || ''}
                      borough={practice.borough || ''}
                      slug={practice.slug || ''}
                    >
                      <div
                        itemProp="address"
                        itemScope
                        itemType="http://schema.org/PostalAddress"
                        className="title is-7 pt-10 pb-10 pl-10 pr-10"
                      >
                        {practice.name} -<span itemProp="addressLocality">{practice.address}</span>,{' '}
                        <span itemProp="addressLocality">{practice.town}</span>{' '}
                        <span itemProp="postalCode">{practice.postCode}</span>
                      </div>
                    </ProfileLink>
                  </div>
                </div>
              </div>
              <div className="is-divider" />
              {index === 0 && (
                <div className="bottomc has-text-centered pt-20">
                  <button
                    className="button is-link is-outlined fontweight-600"
                    onClick={() => dispatch(modalActions.showSpecialistFreeModal(specialist))}
                  >
                    <img src="/images/pencil-icon.png" alt="" />
                    <span className="pl-10">
                      <Trans>MODIFY INFORMATION</Trans>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="cont is-light-bg mb-20 pt-25 pb-25 pl-40 pr-40">
          <div className="columns">
            <div className="column is-9">
              <div className="lt pr-40">
                <div className="fontfamily-roboto title is-5 mb-10">
                  <Trans>Are you a patient? Request an appointment</Trans>
                </div>
                <p>
                  <Trans>
                    Make an enquiry. We will transfer it to the doctor and he will <br /> get back to you to arrange an
                    appointment
                  </Trans>
                </p>
              </div>
            </div>
            <div className="column">
              <div className="rt">
                <EnquireButton
                  profileTypeId={specialist.id}
                  profileTypeName={name}
                  profileType="specialist"
                  text={i18n._(t`ENQUIRE`)}
                  componentClassName="button is-link fontweight-600 pl-50 pr-50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(SpecialistFree);
